<template>
  <div class="spread">
    <p class="page-title">{{menus.name}}</p>
    <div class="spread-container">
      <div class="item" v-for="module in menus.modules" :key="module.uuid">
        <div>
          <span class="item-title">{{module.name}}</span>
          <span class="item-title-tip">{{module.subTitle}}</span>
        </div>

        <el-row>
          <el-col v-for="m in module.modules" :key="m.uuid">
            <div class="function">
              <p class="function-icon"><span class="iconfont " :class="m.iconFront"></span></p>
              <div class="function-name">
                <p>{{m.name}}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: '',
    components: {},
    computed: {
      menus: function () {
        let menus = this.$store.state.account.menus || []
        for (let i = 0; i < menus.length; i++) {
          if (this.$route.name.indexOf(menus[i].engName) >= 0) {
            return menus[i]
          }
        }
        return {
          name: '',
          modules: []
        }
      }
    },
    mounted: function () {
    },
    data: function () {
      return {
        modules: [],
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .spread {
    @include container();

    &-container {
      margin-top: $middle-space;
      .item {
        margin-bottom: $large-space;

        &-title {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          opacity: 0.85;
          margin-left: $small-space;
          margin-bottom: $middle-space;

          &-tip {
            font-size: 12px;
            color: $remark-text-color;
            margin-left: $small-space;
          }
        }

        &-title:before {
          display: inline-block;
          width: 4px;
          height: 16px;
          margin-right: $small-space;
          background: $theme-color;
          vertical-align: -10%;
          content: " ";
        }

        .el-col {
          margin: $small-space 0;
          width: 20%;
          padding-left: $middle-space;

          .function {
            text-align: center;
            cursor: pointer;
            width: 88px;
            padding: $small-space;

            &-icon {
              height: 36px;
              width: 36px;
              margin: auto;
              .iconfont {
                display: inline-block;
                font-size: 32px;
                color: $theme-color;
              }
            }

            &-name {
              margin-top: 4px;
              font-size: 13px;
              font-weight: 500;
              color: $color-black;
            }
          }

          .function:hover {
            background-color: $remark-border-color;
            border-radius: 4px;

            .function-name {
              color: $theme-color;
            }
          }

        }

      }
    }
  }
</style>
